<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <q-btn
          outline
          :disable="siswa.length > 0"
          @click="showDialogImport"
          color="primary"
          label="Import Data Siswa"
        ></q-btn>
        <q-input outlined label="Cari Siswa" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>
              no
              <div class="text-caption text-grey-5">
                total: {{ filSiswa.length }}
              </div>
            </th>
            <th>nisn</th>
            <th>nama</th>
            <th>gender</th>
            <th>sekolah asal</th>
            <th style="width:30px"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-show="siswa.length == 0">
            <td colspan="7" class="text-caption text-grey-7 text-center">
              belum ada data, tekan tombol "import excel" untuk mengimport data
            </td>
          </tr>
          <tr
            v-for="(val, i) in showSiswa"
            :key="i"
            :class="{ 'bg-red-3': val.is_inside }"
          >
            <td>{{ (pagination.current - 1) * 10 + i + 1 }}</td>
            <td>{{ val.nisn }}</td>
            <td>{{ val.nama }}</td>
            <td>{{ val.gender }}</td>
            <td>{{ val.sekolah_asal }}</td>
            <td>
              <q-btn
                flat
                icon="delete_forever"
                color="negative"
                @click="
                  toDel = val;
                  dialogHapus = true;
                "
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <span class="text-red q-ml-sm text-caption"
        >* baris yang berwarna merah menandakan siswa sudah terdaftar pada
        sistem</span
      >
      <q-card-section class="row justify-end">
        <q-pagination
          v-model="pagination.current"
          :disable="siswa.length == 0"
          :max="pagination.max"
          input
          input-class="text-orange-10"
          @input="getPage"
        />
        <q-btn
          v-if="selSumber == 'Pribadi'"
          v-show="siswa.length"
          label="Simpan data"
          color="primary"
          @click="simpan"
        ></q-btn>
        <q-btn
          v-else
          v-show="siswa.length"
          label="Mulai Import"
          color="primary"
          @click="importData"
        ></q-btn>
      </q-card-section>
    </q-card>

    <q-dialog v-model="dialogHapus">
      <q-card>
        <q-card-section>
          Apa Anda Yakin Ingin Menghapus Siswa
          <br />
          <br />
          Nama : {{ toDel.nama }}
          <br />
          NISN : {{ toDel.nisn }}
          <br />
          <br />Dari List Import?
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat color="primary" label="Tidak" v-close-popup></q-btn>
          <q-btn
            unelevated
            color="primary"
            label="Ya"
            @click="delSingle()"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogSiswaImportData from "@/components/DialogSiswaImportData";
import General from "@/mixins/General";

import moment from "moment";

export default {
  mixins: [General],
  data() {
    return {
      searchTerm: "",

      selJenjang: null,

      formatPpdbSiswa: [],
      formatPpdbKeluarga: [],
      formatEmisSiswa: [],
      formatEmisKeluarga: [],
      formatDapodikSiswa: [],
      formatDapodikKeluarga: [],
      formatPribadiSiswa: [],
      formatPribadiKeluarga: [],
      formatMASiswa: [],

      siswa: [],
      ayah: [],
      ibu: [],
      wali: [],
      selJenjang: "",
      selSumber: "",

      pagination: {
        current: 1,
        max: 0,
        limit: 10,
      },
      showSiswa: [],

      dialogHapus: false,
      toDel: {},
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.fetchFormat();
    await this.fetchMemory();
    this.$q.loading.hide();
  },
  computed: {
    filSiswa() {
      let search = this.searchTerm.toLowerCase();
      if (search == "") return this.siswa;
      else {
        let result = this.siswa.filter((val) => {
          let cNama = val.nama.toLowerCase().indexOf(search) != -1;
          let cNISN = val.nisn.indexOf(search) != -1;
          return cNama || cNISN;
        });
        return result;
      }
    },
  },
  methods: {
    async delSingle() {
      let siswa = this.MCopyObject(this.siswa);

      siswa = siswa.filter((val) => {
        return val.nama != this.toDel.nama && val.nisn != this.toDel.nisn;
      });

      this.siswa = siswa;
      await this.getPage();
      this.dialogHapus = false;
    },
    getPage() {
      let search = this.searchTerm.toLowerCase();

      let siswa = this.MCopyObject(this.siswa);
      if (search != "") {
        siswa = siswa.filter((val) => {
          let cNama = val.nama.toLowerCase().indexOf(search) != -1;
          let cNISN = val.nisn.indexOf(search) != -1;
          return cNama || cNISN;
        });
      }

      let show = [];
      for (let i = 0; i < 10; i++) {
        if (siswa[(this.pagination.current - 1) * 10 + i]) {
          show.push(siswa[(this.pagination.current - 1) * 10 + i]);
        }
      }
      this.pagination.max = Math.ceil(siswa.length / 10);
      this.showSiswa = show;
    },
    async simpan() {
      this.$q.loading.show({
        message: "Menyimpan Data...",
      });
      //simpan siswa
      // console.log(this.siswa);
      await this.$http.put(`/siswa/simpan_data/${this.selJenjang}`, this.siswa);

      let bundle = [];
      bundle.push(this.ayah);
      bundle.push(this.ibu);
      bundle.push(this.wali);

      //simpan keluarga
      await this.$http.put(`/keluarga/simpan_data`, bundle);
      await this.fetchMemory();
      this.$q.loading.hide();

      this.pagination.current = 1;
    },

    showDialogImport() {
      this.$q
        .dialog({
          component: DialogSiswaImportData,
          parent: this,
        })
        .onOk(async (resp) => {
          this.selSumber = resp.sumber;
          this.selJenjang = resp.jenjang;
          this.selSumber = resp.sumber;
          console.log("please wait");
          let file = null;
          this.$q.loading.show({ message: "please wait" });
          if (resp.sumber == "Pribadi") {
            file = await this.$hlp.ReadXLSXperSheet(resp.file);
            this.readPribadi(file);
          } else {
            file = await this.$hlp.ReadXLSX(resp.file);
            this.fileReady(file);
          }
        });
    },
    async readPribadi(data) {
      this.$q.loading.show({ message: "please wait" });
      await this.fetchMemory();
      let rows = [];
      for (let item of data) {
        rows.push(item.split("\n"));
      }
      for (let item of rows[0]) {
        let siswa = {};

        let temp = item.split(";");

        if (parseInt(temp[0]) >= 0) {
          for (let fmt of this.formatPribadiSiswa) {
            if (parseInt(fmt.kolom) > 0) {
              siswa[fmt.label] = temp[parseInt(fmt.kolom) - 1];
              if (fmt.label == "tanggal_lahir") {
                siswa[fmt.label] = moment(temp[parseInt(fmt.kolom) - 1]).format(
                  "YYYY-MM-DD"
                );
              }
            }
          }
          this.siswa.push(siswa);
        }
      }

      //data ayah
      for (let item of rows[1]) {
        let ayah = {};
        let temp = item.split(";");
        if (parseInt(temp[0]) >= 0) {
          for (let fmt of this.formatPribadiKeluarga) {
            if (parseInt(fmt.kolom) > 0) {
              ayah[fmt.label] = temp[parseInt(fmt.kolom) - 1];
              if (fmt.label == "tanggal_lahir") {
                ayah[fmt.label] = moment(temp[parseInt(fmt.kolom) - 1]).format(
                  "YYYY-MM-DD"
                );
              }
            }
          }
          this.ayah.push(ayah);
        }
      }

      //data ibu
      for (let item of rows[2]) {
        let ibu = {};
        let temp = item.split(";");
        if (parseInt(temp[0]) >= 0) {
          for (let fmt of this.formatPribadiKeluarga) {
            if (parseInt(fmt.kolom) > 0) {
              ibu[fmt.label] = temp[parseInt(fmt.kolom) - 1];
              if (fmt.label == "tanggal_lahir") {
                ibu[fmt.label] = moment(temp[parseInt(fmt.kolom) - 1]).format(
                  "YYYY-MM-DD"
                );
              }
            }
          }
          this.ibu.push(ibu);
        }
      }

      //data wali
      for (let item of rows[3]) {
        let wali = {};
        let temp = item.split(";");
        if (parseInt(temp[0]) >= 0) {
          for (let fmt of this.formatPribadiKeluarga) {
            if (parseInt(fmt.kolom) > 0) {
              wali[fmt.label] = temp[parseInt(fmt.kolom) - 1];
              if (fmt.label == "tanggal_lahir") {
                wali[fmt.label] = moment(temp[parseInt(fmt.kolom) - 1]).format(
                  "YYYY-MM-DD"
                );
              }
            }
          }
          this.wali.push(wali);
        }
      }
      console.log(this.ayah);
      await this.getPage();
      this.$q.loading.hide();
    },

    readDapodik(data) {
      let siswa = {};
      let ayah = {};
      let ibu = {};
      let wali = {};
      if (parseInt(data[0]) > 0) {
        // insert based on format
        for (let fmt of this.formatDapodikSiswa) {
          if (parseInt(fmt.kolom) > 0) {
            siswa[fmt.label] = data[parseInt(fmt.kolom) - 1];
          }
        }
        if (siswa["rombel_asal"]) {
          siswa.tingkat = siswa["rombel_asal"].substr(5, 1);
          siswa["jenjang"] = "SMP PA";
          if (siswa.rombel_asal.substr(6, 2).toLowerCase() == "pi") {
            siswa["jenjang"] = "SMP PI";
          }
        }

        this.siswa.push(siswa);

        for (let fmt of this.formatDapodikKeluarga) {
          if (parseInt(fmt.kolom_ayah) > 0) {
            ayah[fmt.label] = data[parseInt(fmt.kolom_ayah) - 1];
          }
          if (parseInt(fmt.kolom_ibu) > 0) {
            ibu[fmt.label] = data[parseInt(fmt.kolom_ibu) - 1];
          }
          if (parseInt(fmt.kolom_wali) > 0) {
            wali[fmt.label] = data[parseInt(fmt.kolom_wali) - 1];
          }
        }
        ayah["hubungan"] = "ayah";
        ibu["hubungan"] = "ibu";
        wali["hubungan"] = "wali";

        this.ayah.push(ayah);
        this.ibu.push(ibu);
        this.wali.push(wali);
      }
    },
    readEmis(data) {
      let siswa = {};
      let ayah = {};
      let ibu = {};
      let wali = {};
      if (parseInt(data[9]) > 0) {
        for (let fmt of this.formatEmisSiswa) {
          if (parseInt(fmt.kolom) > 0) {
            siswa[fmt.label] = data[parseInt(fmt.kolom) - 1];
          }
        }
        if (siswa["rombel_asal"]) {
          siswa.tingkat = siswa["rombel_asal"].substr(0, 2);
        }
        siswa["jenjang"] = this.selJenjang;
        siswa["gender"] = "L";

        ayah["hubungan"] = "ayah";
        ibu["hubungan"] = "ibu";
        wali["hubungan"] = "wali";
        this.siswa.push(siswa);
        this.ayah.push(ayah);
        this.ibu.push(ibu);
        this.wali.push(wali);
      }
    },
    readPpdb(data) {
      let siswa = {};
      let ayah = {};
      let ibu = {};
      let wali = {};
      if (parseInt(data[0]) > 0) {
        this.formatPpdbSiswa.forEach((fmt, j) => {
          if (parseInt(fmt.kolom) > 0) {
            siswa[fmt.label] = data[parseInt(fmt.kolom) - 1];
          }
        });
        siswa["jenjang"] = this.selJenjang;
        this.siswa.push(siswa);

        this.formatPpdbKeluarga.forEach((fmt, j) => {
          if (parseInt(fmt.kolom_ibu) > 0) {
            ibu[fmt.label] = data[parseInt(fmt.kolom_ibu) - 1];
          }
        });
        ibu["hubungan"] = "ibu";
        this.ibu.push(ibu);
      }
    },
    readMA(data) {
      let siswa = {};
      let ayah = {};
      let ibu = {};
      let wali = {};
      if (data.length > 1) {
        for (let fmt of this.formatMASiswa) {
          if (parseInt(fmt.kolom) > 0) {
            siswa[fmt.label] = data[parseInt(fmt.kolom) - 1];
          }
        }
        siswa["jenjang"] = this.selJenjang;
        if (siswa["peminatan"] == "") {
          siswa["peminatan"] == "KOSONG";
        }

        ayah["hubungan"] = "ayah";
        ibu["hubungan"] = "ibu";
        wali["hubungan"] = "wali";
        this.siswa.push(siswa);
        this.ayah.push(ayah);
        this.ibu.push(ibu);
        this.wali.push(wali);
      }
    },
    async fileReady(file) {
      let rows = file.split("\n");
      this.fetchMemory();
      let c = 0;

      for (let row of rows) {
        let temp = row.split(";");

        if (this.selSumber == "Dapodik") {
          this.readDapodik(temp);
        } else if (this.selSumber == "EMIS") {
          this.readEmis(temp);
        } else if (this.selSumber == "PPDB") {
          this.readPpdb(temp);
        } else if (this.selSumber == "Import MA") {
          if (c > 0) {
            this.readMA(temp);
          }
        }
        c++;
      }
      let resp = await this.$http.post("/check/is_student", this.siswa);
      let datas = resp.data;
      let siswa = this.MCopyObject(this.siswa);
      siswa.forEach((s, idx) => {
        s.is_inside = datas[idx];
      });
      this.siswa = siswa;
      await this.getPage();
      this.$q.loading.hide();
    },
    fetchMemory() {
      this.ayah = [];
      this.siswa = [];
      this.ibu = [];
      this.wali = [];
      this.bundle = [];
      this.showSiswa = [];
    },
    async fetchFormat() {
      //get format dapodik
      let url = "/format/dapodik/siswa";
      let resp = await this.$http.get(url, this.MGetHeader());
      this.formatDapodikSiswa = resp.data;

      url = "/format/dapodik/keluarga";
      resp = await this.$http.get(url, this.MGetHeader());
      this.formatDapodikKeluarga = resp.data;

      //get format emis
      url = "/format/emis/siswa";
      resp = await this.$http.get(url, this.MGetHeader());
      this.formatEmisSiswa = resp.data;

      //get format pribadi
      url = "/format/pribadi/siswa";
      resp = await this.$http.get(url, this.MGetHeader());
      this.formatPribadiSiswa = resp.data;

      url = "/format/pribadi/keluarga";
      resp = await this.$http.get(url, this.MGetHeader());
      this.formatPribadiKeluarga = resp.data;

      //get format MA
      url = "/format/ma/siswa";
      resp = await this.$http.get(url, this.MGetHeader());
      this.formatMASiswa = resp.data;
    },
    async importData() {
      this.$q.loading.show({ message: "please wait" });

      let siswa = [];
      let ayah = [];
      let ibu = [];
      let wali = [];
      for (let i in this.siswa) {
        if (!this.siswa[i].is_inside) {
          siswa.push(this.siswa[i]);
          ayah.push(this.ayah[i]);
          ibu.push(this.ibu[i]);
          wali.push(this.wali[i]);
        }
      }

      let bundle = [];
      bundle.push(siswa);
      bundle.push(ayah);
      bundle.push(ibu);
      bundle.push(wali);

      await this.$http.post(
        "/siswa/import/multi/" + this.selSumber,
        bundle,
        this.MGetHeader()
      );

      this.fetchMemory();
      this.$q.loading.hide();
      this.$q.notify({
        message: "proses import berhasil",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
